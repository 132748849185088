import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-bootstrap/esm/Col.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-bootstrap/esm/Row.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-bootstrap/esm/Table.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/links/links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SkeletonPersonalBests"] */ "/vercel/path0/src/components/skeleton/index/skeleton-personal-bests.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/skeleton/index/skeleton.module.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/util/datetime.tsx");
